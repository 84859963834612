<template>
  <div class="homeTitWrap">
    <div class="homeTit">
      <span class="tit">{{title}}</span>
      <span class="more" v-if="isMore" @click="goOther">更多 &gt;</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'homeTit',
    props:{
      title:'',
      isMore:false,
      tit2:[],
      type:'',
    },
    data(){
      return {

      }
    },
    components:{
    },
    methods:{
      goOther(){
        this.$router.push({
          name:this.type
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .homeTit{
    display: flex;
    width: 100%;
    .tit{
      flex: 1;
      font-size: 20px;
      color: #D22190;
      font-weight: 700;
    }
    .more{
      display: inline-block;
      width: 50px;
      min-width: 50px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      border-radius: 20px;
      cursor: pointer;
      background:#D22190;
    }
  }
</style>
