<template>
  <div class="noticeWrap">
    <div class="notice">
      <img class="pic" :src="noticeIcon" alt="公告">
      <div class="txt marquee">
        <p v-for="item in noticeCon" v-html="item.title"></p>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'notice',
    data(){
      return {
        noticeIcon:require('@/assets/imgs/home/noticeIcon.png'),
        noticeCon:[],
      }
    },
    components:{
    },
    created(){
      this.getNoticeList()      // 获取公告列表
    },
    methods:{
      getNoticeList(){
        axios.get('/common/getAnnounce').then((res) => {
          if(res.data.state == 200){
            this.noticeCon = res.data.data.list
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .noticeWrap{
    flex: 1;
    .notice{
      display: flex;
      padding: 0 22px;
      align-items: center;
      vertical-align: middle;
      box-sizing: border-box;
      height: 80px;
      background: rgba(0,0,0,0.2);
      .pic{
        margin-right: 10px;
      }
      .txt{
        width: calc(100% - 100px);
        overflow: hidden;
        white-space: nowrap; /* 防止换行 */
        p{
          font-size: 30px;
          word-break: keep-all;
          color: #fff;
          display: inline-block; /* 放置多个p元素 */
          margin: 0 100px 0 30px;
          animation: marquee 30s linear infinite;
        }
      }
    }
  }
  /* 无缝滚动动画 */
  @keyframes marquee {
    0% {transform: translate(0, 0);}
    100% {transform: translate(-100%, 0);}
  }
</style>
