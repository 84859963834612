<template>
  <div class="homeTit2Wrap">
    <div class="homeTit">
      <span class="tit" v-for="(item,index) in title" :class="{active:tit2Active == index}" @click="changeTit(index,item)">{{item}}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'homeTit',
    props:{
      title:[],
      type:''
    },
    data(){
      return {
        tit2Active:0,
      }
    },
    components:{
    },
    methods:{
      changeTit(index,item){
        this.tit2Active = index
        this.$emit('chageTag',{
          ind:index,
          type:this.type,
          item:item,
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .homeTit2Wrap{
    width: 100%;
    .homeTit{
      display: flex;
      .tit{
        margin-right: 30px;
        padding: 2px 0;
        font-size: 15px;
        color: #333333;
        border-bottom: 3px solid transparent;
        cursor: pointer;
      }
      .active{
        color: #D22190;
        border-bottom: 3px solid #D22190;
        cursor: default;
      }
    }

  }
</style>
